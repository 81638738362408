.outer {
  position: relative;
  width: 144px;
  height: 144px;
}
.cultureScoreChart {
  position: absolute;
  bottom: 10%;
}
.percent {
 position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 30px;
    bottom: 25%;
    font-weight: 700;
    color: gray.700;
}

.cultureScoreChartDilation{
  width: 100% !important;
  height: 279px !important;
}

.coreQualitiesDilation{
  width: 100% !important;
  height: 279px !important; 
}

.cultureHealthDilation{
  width: 100% !important;
  height: 279px !important; 
}

