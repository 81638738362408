@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.participant-journey--forward {
  animation: 0.3s ease-in-out 0s 1 slideInFromRight;
}

.participant-journey--backward {
  animation: 0.3s ease-in-out 0s 1 slideInFromLeft;
}

.img-responsive { max-width: 100%; }

